<template>
  <v-app class="bg-background">
    <v-app-bar>
      <template #title>
        <NuxtLink to="/">
          <img :src="logo" class="object-contain h-24 w-24 p-2"></img>
        </NuxtLink>
      </template>
    </v-app-bar>
    <v-main class="bg-background">
      <v-snackbar v-model="website.snackbar.visible" :color="website.snackbar.color" location="top" variant="tonal" :multi-line="true">
        <v-row>
          <v-col class="flex-grow-0 flex-shrink-0 align-self-center">
            <v-icon size="x-large" :icon="website.snackbar.icon" />
          </v-col>
          <v-col class="flex-column">
            <div>{{ website.snackbar.title }}</div>
            <div class="text-xs">
              {{ website.snackbar.text }}
            </div>
          </v-col>
        </v-row>
      </v-snackbar>
      <v-container class="d-flex justify-center bg-transparent m-2">
        <slot />
      </v-container>
      <div class="absolute bottom-0 right-0 text-[10px] text-secondary px-2">
        <span>{{ runtimeConfig.public.mode }}</span> <span>{{ runtimeConfig.public.version }}</span>
      </div>
    </v-main>
  </v-app>
</template>

<script setup>
import logo from '~/assets/img/logo.jpeg'
const website = useWebsiteStore()
const runtimeConfig = useRuntimeConfig()
</script>
